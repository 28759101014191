import React, { Component } from 'react'
import './styles.css'

import Mail from '../images/mail.png'

export class Footer extends Component {
    render() {
        return (
            <footer className='App-Footer' id="footer" >
                <h1>
                    للتواصل:
                    &nbsp;
                    <a href="mailto:amr.and.osaid@gmail.com"><img src={Mail} width="30px" /></a>
                </h1>

                <hr />
                <div>A&O Apps © 2023
                    <br /><br /><br /><br /><br />
                </div>
            </footer>
        )
    }
}

export default Footer