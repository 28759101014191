import React, { useEffect } from 'react'
import { Routes, Route, useLocation } from "react-router-dom"
import './App.css';
import Home from "./pages/Home"
import SalietolaHome from "./pages/salietola_home"
import SalietolaPrivacy from "./pages/salietola_privacy"
import SalietolaGet from './pages/salietola_get'
import ErtawyHome from "./pages/ertawy_home"
import ErtawyPrivacy from "./pages/ertawy_privacy"
import ErtawyGet from './pages/ertawy_get';

function App() {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (hash === '') window.scrollTo(0, 0);
    else
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 0);
  }, [pathname, hash, key]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/salietola" element={<SalietolaHome />} />
        <Route path="/salietolaapp" element={<SalietolaHome />} />
        <Route path="/salietola/privacy" element={<SalietolaPrivacy />} />
        <Route path="/salietolaapp/privacy" element={<SalietolaPrivacy />} />
        <Route path="/salietola/get" element={<SalietolaGet />} />
        <Route path="/salietolaapp/get" element={<SalietolaGet />} />
        <Route path="/ertawy" element={<ErtawyHome />} />
        <Route path="/ertawyapp" element={<ErtawyHome />} />
        <Route path="/ertawy/privacy" element={<ErtawyPrivacy />} />
        <Route path="/ertawyapp/privacy" element={<ErtawyPrivacy />} />
        <Route path="/ertawy/get" element={<ErtawyGet />} />
        <Route path="/ertawyapp/get" element={<ErtawyGet />} />
      </Routes>
    </div>
  );
}

export default App;
