import { Link } from 'react-router-dom';
import './styles.css';

import Header from '../components/Header'
import Footer from '../components/Footer';
import salietolaIcon from '../images/salietola_icon.png';
import ertawyIcon from '../images/ertawy_icon.png';
import cellImg from '../images/salietola/cell2.png';

const SalietolaPrivacy = () => {

    var uiImgWidth = 130;

    return (
        <div>
            <Header />
            <br />


            <div className='subNav'>
                <Link to="/salietola/#appintro"><div>صليت أو لا</div></Link>
                <p>&nbsp;..&nbsp;</p>
                <Link to="/salietola/#features"><div>مميزات التطبيق</div></Link>
                <p>&nbsp;..&nbsp;</p>
                {/* <Link to="/salietola/#salietola"><div>صور التطبيق</div></Link>
                <p>&nbsp;..&nbsp;</p> */}
                <Link to="/salietola/privacy"><div>سياسة الخصوصية</div></Link>
                <p>&nbsp;..&nbsp;</p>
                <Link to="/salietola/#footer"><div>تواصل معنا</div></Link>
            </div>


            {/*  SALIETOLA SECTION  */}
            <h1>سياسة الخصوصية</h1>
            <p>لقد قمنا نحن Amr & Osaid (مزود التطبيق) ببناء تطبيق صليت أو لا (التطبيق) كتطبيق مجاني مع مميزات يتم تفعيلها مقابل مبلغ مالي. إذا اخترت استخدام التطبيق، فأنت توافق على البنود المدرجة في سياسة الخصوصية هذه. يتم استخدام المعلومات التي يجمعها التطبيق لتوفير الخدمة وتحسينها. مزود التطبيق لن يستخدم أو يشارك معلوماتك مع أي شخص باستثناء ما هو موضح في سياسة الخصوصية هذه.</p>
            <br />
            <h2>جمع المعلومات واستخدامها</h2>
            <p>التطبيق لايقوم بتخزين أي معلومات شخصية تخص المستخدم في خوادم خاصة بمزود التطبيق. كل المعلومات التي يجمعها التطبيق تخزن في نفس جهاز المستخدم وذلك لإستخدامها في التطبيق. هذه المعلوات تتضمن موقع ال  GPSالخاص بالجهاز ومعلومات التاريخ والوقت وبعض المعلومات التي تتضمن العمليات التي يقوم بها التطبيق اثناء تشغيلة او من خلال ال background service  التي تعمل اثناء اغلاق التطبيق. هذه المعلومات عادة تسمى بال log data والغرض منها تحسين تجربة المستخدم. لا يتم مشاركة هذه المعلومات لأي احد بإستثناء مزود الخدمه في حاله مواقفة المستخدم على مشاركة هذه البيانات.</p>
            <h2>حق تعديل سياسة الخصوصية</h2>
            <p>مزود التطبيق يملك الحق الكامل في تحديث سياسة الخصوصية الخاصة بالتطبيق سواء بإضافة أو حذف بنود جديدة أو بتعديل بنود حالية في أي وقت. لا نقوم بإرسال أي إشعار أو بريد إلكتروني حول تحديثات سياسة الخصوصية، ولكن يمكنك دائماً معرفة أي تحديث وذلك بتفقد تاريخ آخر تحديث لهذه السياسة والموجود في آخر سطر في هذه الصفحة.</p>
            <h2>اتصل بنا</h2>
            <p>ذا كان لديك أي أسئلة أو اقتراحات حول سياسة الخصوصية، فلا تتردد في الاتصال على amr.and.osaid@gmail.com.</p>
            <p>آخر تحديث: 24 جمادى الأخر 1442</p>


            <Footer />


        </div>
    );
}

export default SalietolaPrivacy;
