import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import appIcon from '../images/icon.png';
// import "antd/dist/antd.css";
import { Drawer, Button } from 'antd';
import { AiOutlineMenu } from "react-icons/ai";

import './styles.css'

const Header = () => {

    return (
        <div className='HomeNavBarBG' id="header">
            <NavBarMobile />
            <NavBarDesktop />
        </div>

    );


}

function NavBarDesktop() {
    return (
        <nav className='HomeNavBar Desktop'>
            <div className='logo'>
                <Link to="/"><img src={appIcon} width="50px" /></Link>
                <Link to="/"><div>A&O Apps</div></Link>
            </div>
            <ul>
                <li><a href="/"> الرئيسية </a></li>
                <li><a href="/#whoweare"> من نحن </a></li>
                <li><a href="/#ourapps"> تطبيقاتنا </a></li>
                <li><a href="#footer"> تواصل معنا </a></li>
            </ul>
        </nav>
    )
}


function NavBarMobile() {

    const [visible, setVisible] = useState(false);

    return (
        <nav className='HomeNavBar Mobile'>
            <div className='logo'>
                <Link to="/"><img src={appIcon} width="50px" /></Link>
                <Link to="/"><div>A&O Apps</div></Link>
            </div>

            <div>

                <AiOutlineMenu
                    onClick={() => {
                        setVisible(true);
                    }} />

                <Drawer
                    title=""
                    placement="left"
                    closable={true}
                    visible={visible}
                    width={200}
                    onClose={() => {
                        setVisible(false)
                    }}
                >

                    <p><a href="/"> الرئيسية </a></p>
                    <p><a href="/#whoweare"> من نحن </a></p>
                    <p><a href="/#ourapps"> تطبيقاتنا </a></p>
                    <p onClick={() => {
                        setVisible(false)
                    }}><a href="#footer"> تواصل معنا </a></p>

                </Drawer>

            </div>
        </nav>
    )

}

export default Header
