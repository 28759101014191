import { Link } from 'react-router-dom';
import './styles.css';

import Header from '../components/Header'
import Footer from '../components/Footer';
import salietolaIcon from '../images/salietola_icon.png';
import ertawyIcon from '../images/ertawy_icon.png';

const Home = () => {

    var uiImgWidth = 130;

    return (
        <div>
            <Header />
            <br />
            <br />
            <br />


            {/*  INTRO SECTION  */}
            <h1 id="whoweare">من نحن</h1>
            <section className="App-Section Intro-Section">
                <div className="Desc">
                    <p>متخصصون في تطبيقات الجوال</p>
                </div>

            </section>
            <br />
            <br />

            {/*  OUT APPS  */}
            <h1 id="ourapps">تطبيقاتنا</h1>
            <section className="App-Section OurApps-Section">
                <Link to="/salietola">
                    <div className="AppItem">
                        <img src={salietolaIcon} width="50px" />
                        <div><b>تطبيق صليت أو لا</b></div>
                        <p>تطبيق خفيف يساعدك تتابع وتحسن من صلواتك يوم بعد يوم بأسلوب ممتع محفز وغير تقليدي</p>
                    </div>
                </Link>
                <Link to="/ertawy">
                    <div className="AppItem">
                        <img src={ertawyIcon} width="50px" />
                        <div><b>تطبيق ارتوي</b></div>
                        <p>استمتع بمتابعة وتحسين شربك للماء يوم بعد يوم بأسلوب ممتع محفز وغير تقليدي</p>
                    </div>
                </Link>

            </section>

            <Footer />


        </div>
    );
}

export default Home;
