import { Link } from 'react-router-dom';
import './styles.css';

import Header from '../components/Header'
import Footer from '../components/Footer';
import salietolaIcon from '../images/salietola_icon.png';
import ertawyIcon from '../images/ertawy_icon.png';
import cellImg from '../images/salietola/cell2.png';
import andImg from '../images/android.webp'
import iosImg from '../images/ios.webp'

const SalietolaHome = () => {

    var uiImgWidth = 130;

    return (
        <div>
            <Header />
            <br />


            <div className='subNav'>
                <Link to="/salietola/#appintro"><div>صليت أو لا</div></Link>
                <p>&nbsp;..&nbsp;</p>
                <Link to="/salietola/#features"><div>مميزات التطبيق</div></Link>
                <p>&nbsp;..&nbsp;</p>
                {/* <Link to="/salietola/#salietola"><div>صور التطبيق</div></Link>
                <p>&nbsp;..&nbsp;</p> */}
                <Link to="/salietola/privacy"><div>سياسة الخصوصية</div></Link>
                <p>&nbsp;..&nbsp;</p>
                <Link to="/salietola/#footer"><div>تواصل معنا</div></Link>
            </div>


            {/*  SALIETOLA SECTION  */}
            <h1 id="appintro"><img src={salietolaIcon} width="50px" />صليت أو لا</h1>
            <section className="App-Section AppIntroSecion" >
                <div className="Desc">
                    <p>تطبيق (صليت أو لا) يختلف عن باقي تطبيقات الصلوات والأذان المتوفرة في متجر التطبيقات.  الميزة الأساسية في صليت أو لا هي إمكانية متابعة صلواتك بغرض تحسينها. فهو يستخدم شخصيات افتراضية وخوارزميات ذكية لتذكيرك بالصلاة بأسلوب ممتع وغير تقليدي. هذا بالإضافة الى توفير الميزات الأساسيه الأخرى من عرض لأوقات الصلوات، توفير منبهات للأذان والإقامة، حساب اتجاه القبلة وغيرها.</p>
                    <br />
                    <a href="https://play.google.com/store/apps/details?id=com.amr_and_osaid.pTracker"><img src={andImg} width="150px" /></a>
                    <a href="https://apps.apple.com/sa/app/id1583992537"><img src={iosImg} width="150px" /></a>
                </div>
                <div className="Cell">
                    <img src={cellImg} width="200px" />
                </div>

            </section>
            <br />
            <br />

            {/*  FEATURES SECTION  */}
            <h1 id="features">ميزات التطبيق</h1>
            <section className="App-Section FeaturesSecion" >

                <div>توثيق ومتابعة أداء الصلاه والصيام</div>
                <div>القرآن الكريم كاملا ومتابعة ختمات القرآن</div>
                <div>بعض الأذكار أساسيه وامكانية اضافة أذكار خاصة</div>
                <div>شخصية افتراضية من اختيارك تساعدك أثناء المتابعة</div>
                <div>عرض أوقات الصلوات وإمكانيه تغيير منهجيه حساب الأوقات</div>
                <div>تحديد القبلة</div>
                <div>منبهات للأذان والإقامة والأذكار وغيرها</div>
                <div>عرض التاريخ الهجري والميلادي</div>
                <div>ثيمات متعددة تناسب شخصيتك</div>
                <div>تطبيق مصغر لإصدار الاندرويد</div>


            </section>
            <br />
            <br />


            <Footer />


        </div>
    );
}

export default SalietolaHome;
