import { Link } from 'react-router-dom';
import './styles.css';

import Header from '../components/Header'
import Footer from '../components/Footer';
import ertawyIcon from '../images/ertawy_icon.png';
import cellImg from '../images/ertawy/cell2.jpg';
import andImg from '../images/android.webp'
import iosImg from '../images/ios.webp'

const ErtawyHome = () => {

    var uiImgWidth = 130;

    return (
        <div>
            <Header />
            <br />


            <div className='subNav'>
                <Link to="/ertawy/#appintro"><div>ارتوي</div></Link>
                <p>&nbsp;..&nbsp;</p>
                <Link to="/ertawy/#features"><div>مميزات التطبيق</div></Link>
                <p>&nbsp;..&nbsp;</p>
                {/* <Link to="/salietola/#salietola"><div>صور التطبيق</div></Link>
                <p>&nbsp;..&nbsp;</p> */}
                <Link to="/ertawy/privacy"><div>سياسة الخصوصية</div></Link>
                <p>&nbsp;..&nbsp;</p>
                <Link to="/ertawy/#footer"><div>تواصل معنا</div></Link>
            </div>


            {/*  SALIETOLA SECTION  */}
            <h1 id="appintro"><img src={ertawyIcon} width="50px" />ارتوي</h1>
            <section className="App-Section AppIntroSecion" >
                <div className="Desc">
                    <p>​تطبيق (ارتوي) يختلف عن باقي تطبيقات متابعة شرب الماء المتوفرة في متجر التطبيقات. الميزة الأساسية في تطبيق ارتوي هي جمال التصميم وسهولة الاستخدام. يقوم التطبيق بحساب كمية الماء اليومية التقريبية المناسبة لجسمك وبإمكانك متابعه كميه الماء بشكل يومي وعرض تقارير يومية، اسبوعية، شهرية وسنويه ومشاركتها مع من تحب.</p>
                    <br />
                    <a href="https://play.google.com/store/apps/details?id=com.amr_and_osaid.pTracker"><img src={andImg} width="150px" /></a>
                    <a href="https://apps.apple.com/sa/app/id1583992537"><img src={iosImg} width="150px" /></a>
                </div>
                <div className="Cell">
                    <img src={cellImg} width="200px" />
                </div>

            </section>
            <br />
            <br />

            {/*  FEATURES SECTION  */}
            <h1 id="features">ميزات التطبيق</h1>
            <section className="App-Section FeaturesSecion" >

                <div>حساب كمية الماء التقريبية المناسبة لجسمك</div>
                <div>توثيق ومتابعة كمية الماء اليومية</div>
                <div>عرض تقييمك بشكل يومي اسبوعي شهري وسنوي</div>
                <div>منبهات تذكيرية </div>

            </section>
            <br />
            <br />


            <Footer />


        </div>
    );
}

export default ErtawyHome;
